import { createContext, useState, useContext, useEffect } from "react";
import PropTypes from "prop-types"; // Importez PropTypes

// Créez le contexte
const ThemeContext = createContext();

// Fournisseur de thème
export const ThemeProvider = ({ children }) => {
  const [theme, setTheme] = useState("light");

  // Si vous avez un système pour stocker les préférences utilisateur, chargez-les ici
  useEffect(() => {
    const savedTheme = localStorage.getItem("theme") || "light";
    setTheme(savedTheme);
  }, []);

  // Mettez à jour le thème dans le local storage à chaque changement
  useEffect(() => {
    localStorage.setItem("theme", theme);
    document.documentElement.setAttribute("data-theme", theme);
  }, [theme]);

  const toggleTheme = () => {
    setTheme((prevTheme) => (prevTheme === "light" ? "dark" : "light"));
  };

  const disableDarkMode = () => {
    setTheme("light");
  };

  return (
    <ThemeContext.Provider value={{ theme, toggleTheme, disableDarkMode }}>
      {children}
    </ThemeContext.Provider>
  );
};

// Ajoutez la validation PropTypes pour `children`
ThemeProvider.propTypes = {
  children: PropTypes.node.isRequired, // `children` est un "node" et est requis
};

// Hook pour utiliser le contexte
export const useTheme = () => useContext(ThemeContext);

