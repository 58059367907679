import { useRef, useState, useEffect, useMemo } from "react";
import emailjs from "emailjs-com";

export const Formulaire = () => {
  const formRef = useRef();

  // Tableaux associatifs pour les montants selon le QF et les heures
  // 7,35€/heure
  const PrixFinalQF1 = useMemo(
    () => ({
      1: { 4.5: 19, 9: 38, 18: 76, 27: 114, 36: 152, 45: 300 },
      2: { 4.5: 19, 9: 38, 18: 76, 27: 114, 36: 180, 45: 434 },
      3: { 4.5: 19, 9: 38, 18: 76, 27: 114, 36: 314, 45: 567 },
    }),
    []
  );

  // 7,70€/heure
  const PrixFinalQF2 = useMemo(
    () => ({
      1: {
        // Niveau de revenu 1
        4.5: 20,
        9: 40,
        18: 80,
        27: 120,
        36: 159,
        45: 360,
      },
      2: {
        // Niveau de revenu 2
        4.5: 20,
        9: 40,
        18: 80,
        27: 120,
        36: 228,
        45: 494,
      },
      3: {
        // Niveau de revenu 3
        4.5: 20,
        9: 40,
        18: 80,
        27: 120,
        36: 363,
        45: 627,
      },
    }),
    []
  );

  // 8€/heure
  const PrixFinalQF3 = useMemo(
    () => ({
      1: {
        // Niveau de revenu 1
        4.5: 21,
        9: 41,
        18: 83,
        27: 124,
        36: 166,
        45: 412,
      },
      2: {
        // Niveau de revenu 2
        4.5: 21,
        9: 41,
        18: 83,
        27: 124,
        36: 270,
        45: 546,
      },
      3: {
        // Niveau de revenu 3
        4.5: 21,
        9: 41,
        18: 83,
        27: 124,
        36: 403,
        45: 679,
      },
    }),
    []
  );

  // 8,10€/heure
  const PrixFinalQF4 = useMemo(
    () => ({
      1: {
        // Niveau de revenu 1
        4.5: 21,
        9: 42,
        18: 84,
        27: 126,
        36: 168,
        45: 429,
      },
      2: {
        // Niveau de revenu 2
        4.5: 21,
        9: 42,
        18: 84,
        27: 126,
        36: 284,
        45: 563,
      },
      3: {
        // Niveau de revenu 3
        4.5: 21,
        9: 42,
        18: 84,
        27: 126,
        36: 417,
        45: 696,
      },
    }),
    []
  );

  // 8,20€/heure
  const PrixFinalQF5 = useMemo(
    () => ({
      1: {
        // Niveau de revenu 1
        4.5: 21,
        9: 42,
        18: 85,
        27: 127,
        36: 170,
        45: 447,
      },
      2: {
        // Niveau de revenu 2
        4.5: 21,
        9: 42,
        18: 85,
        27: 127,
        36: 297,
        45: 580,
      },
      3: {
        // Niveau de revenu 3
        4.5: 21,
        9: 42,
        18: 85,
        27: 127,
        36: 431,
        45: 714,
      },
    }),
    []
  );

  // 8,30€/heure
  const PrixFinalQF6 = useMemo(
    () => ({
      1: {
        // Niveau de revenu 1
        4.5: 21,
        9: 43,
        18: 86,
        27: 129,
        36: 172,
        45: 464,
      },
      2: {
        // Niveau de revenu 2
        4.5: 21,
        9: 43,
        18: 86,
        27: 129,
        36: 311,
        45: 597,
      },
      3: {
        // Niveau de revenu 3
        4.5: 21,
        9: 43,
        18: 86,
        27: 129,
        36: 445,
        45: 731,
      },
    }),
    []
  );

  const handleReset = (e) => {
    e.preventDefault();

    setPrenom("");
    setAge("");
    setTerme("");
    setNom("");
    setEmploiParent1("");
    setTelephoneParent1("");

    setNom2("");
    setEmploiParent2("");
    setTelephoneParent2("");
    setSituation("");
    setNumbercaf("");

    setEmail("");
    setAddress("");
    setCity("");
    setZipcode("");
    setNombreEnfants("");
    setRevenu("");

    setMontantAide("");
    setSelectedTableQF("");
    setprixFinal("");
    setSelectionDay("");
    setSelectedHours(""); // Réinitialiser les heures sélectionnées lorsque le quotient familial change
    setMonthlyCost(""); // Réinitialiser le coût mensuel
    // setSelectionDay(""); // Réinitialiser la sélection des jours et créneaux horaires
  };


  const jours = ['Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi'];

  // États pour la première partie du formulaire
  const [prenom, setPrenom] = useState("");
  const [age, setAge] = useState("");
  const [terme, setTerme] = useState("");
  
  const [nom, setNom] = useState("");
  const [emploiParent1, setEmploiParent1] = useState("");
  const [telephoneParent1, setTelephoneParent1] = useState("");
  
  const [nom2, setNom2] = useState("");
  const [emploiParent2, setEmploiParent2] = useState("");
  const [telephoneParent2, setTelephoneParent2] = useState("");
  
  const [numbercaf, setNumbercaf] = useState("");
  const [situation, setSituation] = useState("");
  const [email, setEmail] = useState("");
  const [address, setAddress] = useState("");
  const [city, setCity] = useState("");
  const [zipcode, setZipcode] = useState("");

  const [selectionDay, setSelectionDay] = useState({
    lundi: { timeSlots: { matin: false, midi: false, "apres-midi": false } },
    mardi: { timeSlots: { matin: false, midi: false, "apres-midi": false } },
    mercredi: { timeSlots: { matin: false, midi: false, "apres-midi": false } },
    jeudi: { timeSlots: { matin: false, midi: false, "apres-midi": false } },
    vendredi: { timeSlots: { matin: false, midi: false, "apres-midi": false } },
  });

  // États pour les calculs
  const [nombreEnfants, setNombreEnfants] = useState("");
  const [revenu, setRevenu] = useState("");
  const [montantAide, setMontantAide] = useState("");
  const [selectedTableQF, setSelectedTableQF] = useState("");
  const [selectedHours, setSelectedHours] = useState("");
  const [monthlyCost, setMonthlyCost] = useState("");
  const [prixFinal, setprixFinal] = useState("");

 

  const handleNombreEnfantsChange = (e) => {
    setNombreEnfants(e.target.value);
  };

  const handleRevenuChange = (e) => {
    setRevenu(e.target.value);
  };

  // Fonction pour gérer le changement du quotient familial
  const handleTableChange = (e) => {
    setSelectedTableQF(e.target.value);
    setSelectedHours(""); // Réinitialiser les heures sélectionnées lorsque le quotient familial change
    setMonthlyCost(""); // Réinitialiser le coût mensuel
    setprixFinal(""); // Réinitialiser le coût final
  };

  // Fonction pour gérer la sélection du nombre d'heures par semaine
  const handleHoursChange = (e) => {
    const hours = e.target.value;
    setSelectedHours(hours);

    // Tarifs horaires en fonction des différents quotients familiaux
    // const tarifHour1 = { 7.35},
    const tarifParHeureQF1 = {
      4.5: 127,
      9: 254,
      18: 507,
      27: 761,
      36: 1014,
      45: 1268,
    };

    // 7,70€/h
    const tarifParHeureQF2 = {
      4.5: 133,
      9: 266,
      18: 531,
      27: 797,
      36: 1063,
      45: 1328,
    };

    // 8€/h
    const tarifParHeureQF3 = {
      4.5: 138,
      9: 276,
      18: 552,
      27: 828,
      36: 1104,
      45: 1380,
    };

    // 8€/h
    const tarifParHeureQF4 = {
      4.5: 140,
      9: 279,
      18: 559,
      27: 838,
      36: 1118,
      45: 1397,
    };

    // 8,10€/h
    const tarifParHeureQF5 = {
      4.5: 141,
      9: 283,
      18: 566,
      27: 849,
      36: 1132,
      45: 1415,
    };

    // 8,20€/h
    const tarifParHeureQF6 = {
      4.5: 143,
      9: 286,
      18: 573,
      27: 859,
      36: 1145,
      45: 1432,
    };

    // Sélectionner le tarif approprié en fonction du quotient familial choisi
    let cost = 0;
    switch (selectedTableQF) {
      case "1":
        cost = tarifParHeureQF1[hours];
        break;
      case "2":
        cost = tarifParHeureQF2[hours];
        break;
      case "3":
        cost = tarifParHeureQF3[hours];
        break;

      case "4":
        cost = tarifParHeureQF4[hours];
        break;

      case "5":
        cost = tarifParHeureQF5[hours];
        break;

      case "6":
        cost = tarifParHeureQF6[hours];
        break;

      default:
        cost = 0;
    }

    setMonthlyCost(cost ? `${cost} €` : "");
  };

  useEffect(() => {
    let selectedRevenu = 0;

    if (revenu) {
      selectedRevenu = parseFloat(revenu);
    }

    setMontantAide(selectedRevenu.toFixed(2));
  }, [revenu]);

  useEffect(() => {
    if (selectedTableQF && selectedHours && revenu) {
      let prixFinalTable;

      // Tableau pour mapper les différentes valeurs QF
      const prixTables = [
        // null,  // Attention à cet index, il ne faut pas qu'il soit utilisé.
        PrixFinalQF1,
        PrixFinalQF2,
        PrixFinalQF3,
        PrixFinalQF4,
        PrixFinalQF5,
        PrixFinalQF6,
      ];
      prixFinalTable = prixTables[selectedTableQF];

      if (prixFinalTable) {
        // Déterminez la clé à utiliser pour le revenu
        const revenuKey =
          revenu === "967.81"
            ? "1"
            : revenu === "834.28"
            ? "2"
            : revenu === "700.80"
            ? "3"
            : null;

        const hoursKey = selectedHours; // On s'assure ici qu'il est bien un string compatible avec la clé.

        // Vérifiez que le revenuKey et hoursKey existent dans le prixFinalTable
        const prix =
          revenuKey && prixFinalTable[revenuKey]
            ? prixFinalTable[revenuKey][hoursKey]
            : null;

        // Mise à jour de prixFinal avec le montant calculé
        setprixFinal(prix !== null && prix !== undefined ? prix : null);
      }
    }
  }, [
    
    selectedTableQF,
    selectedHours,
    revenu,
    PrixFinalQF1,
    PrixFinalQF2,
    PrixFinalQF3,
    PrixFinalQF4,
    PrixFinalQF5,
    PrixFinalQF6,
  ]);

  const handleTimeSlotChange = (jour, timeSlot, checked) => {
    setSelectionDay((prevState) => ({
      ...prevState,
      [jour]: {
        ...prevState[jour],
        timeSlots: {
          ...prevState[jour].timeSlots,
          [timeSlot]: checked,
        },
      },
    }));
  };
 

  // Fonction pour gérer la soumission du formulaire
  const handleSubmit = (event) => {
    event.preventDefault(); // Empêche le rechargement de la page
    const selectionDays2 = [];
    jours.forEach((jour) => {
      const daySelection = selectionDay[jour.toLowerCase()];
      if (daySelection) {
        const selectedSlots = Object.keys(daySelection.timeSlots).filter(
          (timeSlot) => daySelection.timeSlots[timeSlot]
        );
        if (selectedSlots.length > 0) {
          selectionDays2.push(`${jour}: ${selectedSlots.join(", ")}`);
        }
      }
    });

    // Mettre à jour le champ caché avec la sélection des jours
    formRef.current.selectionDaySummary.value = selectionDays2.join("; ");


    // Envoi de l'email via EmailJS
    emailjs
      .sendForm(
        "service_q1fcysk",
        "template_6al1j5x",
        formRef.current,
        "eNwtxnGJxC-ka6U_A"
      )
      .then(() => {
        alert("Message envoyé avec succès !");
      })
      .catch((error) => {
        alert("Erreur lors de l'envoi du message. Veuillez réessayer.");
        console.error("Erreur:", error);
      });
  };

  return (
    <>
      <section className="container mx-auto my-16 mt-36">
        <div>
          <div className="min-h-screen p-6 flex items-center justify-center">
            {/* Formulaire */}
            <form ref={formRef} onSubmit={handleSubmit} name="formulaire">
             
              <div className="container max-w-screen-lg mx-auto">
                <div>
                  <h2 className="font-semibold text-4xl text-green-800">
                    Formulaire de pré-inscription
                  </h2>
                  {/* <p className="text-gray-500 mb-6">En cas de questions, merci de les indiquer dans la case
                                        commentaire.</p> */}

                  <div className="mt-3 bg-gradient-to-r from-red-200 to-indigo-200 rounded shadow-lg p-4 px-4 md:p-8 mb-6">
                    <div className="text-gray-600">
                      <p className="font-semibold text-xl mb-7 text-green-900 ">
                        Vos informations :
                      </p>
                      {/* <p className="text-sm">Aucune donnée personnelle n'est conservée par notre site via ce
                                                formulaire.</p> */}
                    </div>
                    <div className="grid gap-4 gap-y-2 text-sm grid-cols-1 lg:grid-cols-1">
                      <div className="lg:col-span-2">
                        <div className="md:col-span-5">
                          <div className="grid gap-4 gap-y-2 text-sm grid-cols-1 md:grid-cols-1">
                            <label className="lg:text-lg" htmlFor="Prenom">
                              Prénom et nom de l&rsquo;enfant
                            </label>
                            <input
                              type="text"
                              name="prenom"
                              id="prenom"
                              className="h-10 border mt-1 rounded px-4 w-full bg-gray-50"
                              value={prenom}
                              onChange={(e) => setPrenom(e.target.value)}
                            />
                          </div>

                          <div className="flex-1 md:col-span-5 mt-3 flex flex-wrap gap-4">
                            <div className="md:col-span-5">
                              <label className="lg:text-lg" htmlFor="age">
                                Date de naissance de l&rsquo;enfant
                              </label>
                              <input
                                type="date"
                                name="age"
                                id="age"
                                className="h-10 border mt-1 rounded px-4 w-full bg-gray-50"
                                value={age}
                                onChange={(e) => setAge(e.target.value)}
                              />
                            </div>

                            <div className="flex-1 md:col-span-5">
                              <label className="lg:text-lg" htmlFor="terme">
                                ou date du terme
                              </label>
                              <input
                                type="date"
                                name="terme"
                                id="terme"
                                className="h-10 border mt-1 rounded px-4 w-full bg-gray-50"
                                value={terme}
                                onChange={(e) => setTerme(e.target.value)}
                              />
                            </div>
                          </div>

                          <div className="flex justify-center my-10">
                            <hr className="border border-white w-1/2" />
                          </div>

                          <div className="md:col-span-5 flex flex-wrap gap-4">
                            <div className="flex-1">
                              <label className="lg:text-lg" htmlFor="nom">
                                Prénom et Nom{" "}
                                <div className="text-xs">(parent 1)</div>
                              </label>
                              <input
                                type="text"
                                name="nom"
                                id="nom"
                                className="h-10 border mt-1 rounded px-4 w-full bg-gray-50"
                                value={nom}
                                onChange={(e) => setNom(e.target.value)}
                              />
                            </div>

                            <div className="flex-1">
                              <label
                                className="lg:text-lg"
                                htmlFor="emploiParent1">
                                Catégorie socio-professionnelle
                                <div className="text-xs">(parent 1)</div>
                              </label>
                              <input
                                type="text"
                                name="emploiParent1"
                                id="emploiParent1"
                                className="h-10 border mt-1 rounded px-4 w-full bg-gray-50"
                                value={emploiParent1}
                                onChange={(e) =>
                                  setEmploiParent1(e.target.value)
                                }
                              />
                            </div>

                            <div className="flex-4">
                              <label
                                className="lg:text-lg"
                                htmlFor="telephoneParent1">
                                Téléphone
                                <div className="text-xs">(parent 1)</div>
                              </label>
                              <input
                                type="tel"
                                name="telephoneParent1"
                                id="telephoneParent1"
                                className="h-10 border mt-1 rounded px-4 w-full bg-gray-50"
                                value={telephoneParent1}
                                onChange={(e) =>
                                  setTelephoneParent1(e.target.value)
                                }
                              />
                            </div>
                          </div>

                          <div className="md:col-span-5 mt-3 flex flex-wrap gap-4">
                            <div className="flex-1">
                              <label className="lg:text-lg" htmlFor="nom2">
                                Prénom et Nom
                                <div className="text-xs">(parent 2)</div>
                              </label>
                              <input
                                type="text"
                                name="nom2"
                                id="nom2"
                                className="h-10 border mt-1 rounded px-4 w-full bg-gray-50"
                                value={nom2}
                                onChange={(e) => setNom2(e.target.value)}
                              />
                            </div>

                            <div className="flex-1">
                              <label
                                className="lg:text-lg"
                                htmlFor="emploiParent2">
                                Catégorie socio-professionnelle
                                <div className="text-xs">(parent 1)</div>
                              </label>
                              <input
                                type="text"
                                name="emploiParent2"
                                id="emploiParent2"
                                className="h-10 border mt-1 rounded px-4 w-full bg-gray-50"
                                value={emploiParent2}
                                onChange={(e) =>
                                  setEmploiParent2(e.target.value)
                                }
                              />
                            </div>

                            <div className="flex-4">
                              <label
                                className="lg:text-lg"
                                htmlFor="telephoneParent2">
                                Téléphone
                                <div className="text-xs">(parent 2)</div>
                              </label>
                              <input
                                type="tel"
                                name="telephoneParent2"
                                id="telephoneParent2"
                                className="h-10 border mt-1 rounded px-4 w-full bg-gray-50"
                                value={telephoneParent2}
                                onChange={(e) =>
                                  setTelephoneParent2(e.target.value)
                                }
                              />
                            </div>
                          </div>

                          <div className="md:col-span-3 mt-5">
                            <label className="text-sm" htmlFor="situation">
                              Situation famillial
                            </label>
                            <select
                              className="block w-full px-4 py-2 mt-1 text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none
                                                                 focus:ring-blue-500 focus:border-blue-500"
                              name="situation"
                              id="situation"
                              value={situation}
                              onChange={(e) => setSituation(e.target.value)}>
                              <option value="maries">Mariés</option>
                              <option value="Pacses">Pacsés</option>
                              <option value="concubins">Concubins</option>
                              <option value="separes_ou_divorce">
                                Séparés / divorcés
                              </option>
                              <option value="parent_seul">Parent isolé</option>
                            </select>
                          </div>

                          <div className="md:col-span-5 mt-3">
                            <label className="lg:text-lg" htmlFor="numbercaf">
                              Numéro de la CAF
                            </label>
                            <input
                              type="number"
                              name="numbercaf"
                              id="numbercaf"
                              className="h-10 border mt-1 rounded px-4 w-full bg-gray-50"
                              value={numbercaf}
                              onChange={(e) => setNumbercaf(e.target.value)}
                            />
                          </div>

                          <div className="flex justify-center my-10">
                            <hr className="border border-white w-1/2" />
                          </div>

                          <div className="md:col-span-5 mt-3">
                            <label className="lg:text-lg" htmlFor="email">
                              Mail
                            </label>
                            <input
                              type="text"
                              name="email"
                              id="email"
                              className="h-10 border mt-1 rounded px-4 w-full bg-gray-50"
                              value={email}
                              onChange={(e) => setEmail(e.target.value)}
                            />
                          </div>

                          <div className="md:col-span-3 mt-3">
                            <label className="lg:text-lg" htmlFor="address">
                              Adresse
                            </label>
                            <input
                              type="text"
                              name="address"
                              id="address"
                              className="h-10 border mt-1 rounded px-4 w-full bg-gray-50"
                              value={address}
                              onChange={(e) => setAddress(e.target.value)}
                              placeholder=""
                            />
                          </div>

                          <div className="md:col-span-2 mt-3">
                            <label className="lg:text-lg" htmlFor="zipcode">
                              Code Postal
                            </label>
                            <input
                              type="text"
                              name="zipcode"
                              id="zipcode"
                              className="transition-all flex items-center h-10 border mt-1 rounded px-4 w-full bg-gray-50"
                              value={zipcode}
                              onChange={(e) => setZipcode(e.target.value)}
                            />
                          </div>

                          <div className="md:col-span-5 mt-3">
                            <label className="lg:text-lg" htmlFor="city">
                              Ville
                            </label>
                            <input
                              type="text"
                              name="city"
                              id="city"
                              className="h-10 border mt-1 rounded px-4 w-full bg-gray-50"
                              value={city}
                              onChange={(e) => setCity(e.target.value)}
                            />
                          </div>


      {/* Sélection des jours */}
      <div className="md:col-span-5 mt-5">
  <label className="block text-lg font-semibold text-gray-700 mb-2" htmlFor="selectionDay">
    Jours souhaités
  </label>
  <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
    {jours.map((jour) => (
      <div key={jour} className="bg-white shadow-md rounded-lg p-4">
        <h3 className="text-md font-medium text-gray-800 mb-3">{jour}</h3>
        <div className="space-y-2">
          {/* Matin */}
          <label className="flex items-center space-x-2">
            <input
              type="checkbox"
              onChange={(e) =>
                handleTimeSlotChange(jour.toLowerCase(), "matin", e.target.checked)
              }
              checked={selectionDay[jour.toLowerCase()]?.timeSlots?.matin || false}
              className="h-4 w-4 text-blue-600 border-gray-300 rounded focus:ring-blue-500"
            />
            <span className="text-sm text-gray-700">Matin</span>
          </label>
          {/* Midi/Repas */}
          <label className="flex items-center space-x-2">
            <input
              type="checkbox"
              onChange={(e) =>
                handleTimeSlotChange(jour.toLowerCase(), "midi", e.target.checked)
              }
              checked={selectionDay[jour.toLowerCase()]?.timeSlots?.midi || false}
              className="h-4 w-4 text-blue-600 border-gray-300 rounded focus:ring-blue-500"
            />
            <span className="text-sm text-gray-700">Midi/Repas</span>
          </label>
          {/* Après-midi */}
          <label className="flex items-center space-x-2">
            <input
              type="checkbox"
              onChange={(e) =>
                handleTimeSlotChange(jour.toLowerCase(), "apres-midi", e.target.checked)
              }
              checked={selectionDay[jour.toLowerCase()]?.timeSlots?.["apres-midi"] || false}
              className="h-4 w-4 text-blue-600 border-gray-300 rounded focus:ring-blue-500"
            />
            <span className="text-sm text-gray-700">Après-midi</span>
          </label>
        </div>
      </div>
    ))}
  </div>

  {/* Champ caché pour la sélection des jours */}
  <input type="hidden" name="selectionDaySummary" />
</div>


                          <div className="md:col-span-3 mt-10 border-gray-500 border rounded-md p-2">
                            <p className="ml-6 w-full text-green-700 italic flex justify-center">
                              Ce formulaire est une simulation, les résultats
                              sont donnés à titre indicatif et non contractuels.
                            </p>

                            <div className="flex flex-col md:flex-row justify-center md:space-x-6 p-5 md:w-full mt-3">
                              {/* Estimation Creche */}
                              <div className="md:w-3/5 w-full  md:col-span-8 lg:col-span-12 bg-blue-200 rounded-md p-5 h-3/4">
                                <div>
                                  <p className="lg:text-sm font-semibold text-indigo-900">
                                    Tarifications Micro-crèche
                                  </p>
                                </div>
                                <div className="md:col-span-5 mt-2"></div>

                                {/* Sélection du quotient familial */}
                                <div className="md:col-span-3 mt-3">
                                  <label
                                    className="text-sm"
                                    htmlFor="selectedTableQF">
                                    Sélectionner votre quotient familial
                                  </label>
                                  <select
                                    name="selectedTableQF"
                                    id="selectedTableQF"
                                    className="block w-full px-4 py-2 mt-1 text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none
                                                                 focus:ring-blue-500 focus:border-blue-500"
                                    value={selectedTableQF}
                                    onChange={handleTableChange}>
                                    <option value="0">
                                      Sélectionner votre quotient familial
                                    </option>
                                    <option value="1">0 - 750 €</option>
                                    <option value="2">750 - 1000 €</option>
                                    <option value="3">1000 - 1250 €</option>
                                    <option value="4">1250 - 1500 €</option>
                                    <option value="5">1500 - 1750 €</option>
                                    <option value="6">
                                      supérieur à 1750 €
                                    </option>
                                  </select>
                                </div>

                                <div className="">
                                  {selectedTableQF === "1" && (
                                    <div
                                      id="TQF1"
                                      value={selectedTableQF}
                                      onChange={handleTableChange}
                                      className="mt-2">
                                      <div className=" border border-gray-300 rounded-md">
                                        <p className="p-2 md:text-sm font-semibold text-red-800 border-white rounded-t-md">
                                          Tarif horaire à 7,35€/h
                                        </p>
                                      </div>
                                    </div>
                                  )}
                                </div>

                                <div className="">
                                  {selectedTableQF === "2" && (
                                    <div
                                      id="TQF1"
                                      value={selectedTableQF}
                                      onChange={handleTableChange}
                                      className="mt-2">
                                      <div className=" border border-gray-300 rounded-md">
                                        <p className="p-2 md:text-sm font-semibold text-red-800 border-gray-300 rounded-t-md">
                                          Tarif horaire à 7,70€/h
                                        </p>
                                      </div>
                                    </div>
                                  )}
                                </div>

                                <div className="">
                                  {selectedTableQF === "3" && (
                                    <div
                                      id="TQF1"
                                      value={selectedTableQF}
                                      onChange={handleTableChange}
                                      className="mt-2">
                                      <div className=" border border-gray-300 rounded-md">
                                        <p className="p-2 md:text-sm font-semibold text-red-800 border-gray-300 rounded-t-md">
                                          Tarif horaire à 8€/h
                                        </p>
                                      </div>
                                    </div>
                                  )}
                                </div>

                                <div className="">
                                  {selectedTableQF === "4" && (
                                    <div
                                      id="TQF1"
                                      value={selectedTableQF}
                                      onChange={handleTableChange}
                                      className="mt-2">
                                      <div className=" border border-gray-300 rounded-md">
                                        <p className="p-2 md:text-sm font-semibold text-red-800 border-gray-300 rounded-t-md">
                                          Tarif horaire à 8,10€/h
                                        </p>
                                      </div>
                                    </div>
                                  )}
                                </div>

                                <div className="">
                                  {selectedTableQF === "5" && (
                                    <div
                                      id="TQF1"
                                      value={selectedTableQF}
                                      onChange={handleTableChange}
                                      className="mt-2">
                                      <div className=" border border-gray-300 rounded-md">
                                        <p className="p-2 md:text-sm font-semibold text-red-800 border-gray-300 rounded-t-md">
                                          Tarif horaire à 8,20€/h
                                        </p>
                                      </div>
                                    </div>
                                  )}
                                </div>

                                <div className="">
                                  {selectedTableQF === "6" && (
                                    <div
                                      id="TQF1"
                                      value={selectedTableQF}
                                      onChange={handleTableChange}
                                      className="mt-2">
                                      <div className="border border-white rounded-md">
                                        <p className="p-2 md:text-sm font-semibold text-red-800 border-gray-300 rounded-t-md">
                                          Tarif horaire à 8,30€/h
                                        </p>
                                      </div>
                                    </div>
                                  )}
                                </div>

                                {/* // Sélection des heures si applicable */}
                                {selectedTableQF && (
                                  <div className="md:col-span-3 mt-3">
                                    <label
                                      className="text-md mt-3"
                                      htmlFor="selectedHours">
                                      Nombre d&rsquo;heures d&rsquo;accueil par
                                      semaine
                                    </label>
                                    <select
                                      className="block w-full px-4 py-2 mt-1 text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm 
                                                                    focus:outline-none focus:ring-blue-500 focus:border-blue-500"
                                      id="selectedHours"
                                      name="selectedHours"
                                      value={selectedHours}
                                      onChange={handleHoursChange}>
                                      <option value="">
                                        Sélectionner les heures
                                      </option>
                                      <option value="4.5">
                                        4.5 heures par semaine
                                      </option>
                                      <option value="9">
                                        9 heures par semaine
                                      </option>
                                      <option value="18">
                                        18 heures par semaine
                                      </option>
                                      <option value="27">
                                        27 heures par semaine
                                      </option>
                                      <option value="36">
                                        36 heures par semaine
                                      </option>
                                      <option value="45">
                                        45 heures par semaine
                                      </option>
                                    </select>
                                  </div>
                                )}

                                <div className="border border-blue-400 p-2 rounded-md mt-4">
                                  <p className="md:text-md text-green-900 ">
                                    Coût mensuel
                                  </p>
                                  <p className="font-light italic text-xs">
                                    (PAJE non déduite)
                                  </p>
                                  
                                  
                                  {/* Affichage du coût mensuel */}
                                  {monthlyCost && (
                                    <div className="mt-3">
                                      <p
                                        id="monthlyCost"
                                        name="monthlyCost"
                                        value={monthlyCost}
                                        onChange={(e) =>
                                          setMonthlyCost(e.target.value)
                                        }
                                        className="text-red-800 text-2xl mt-4">
                                        {monthlyCost}
                                      </p>
                                    </div>
                                  )}
                                </div>
                              </div>

                              {/* Formulaire calcul PAJE */}
                              <div className="bg-blue-200 p-5 rounded-md md:w-2/4">
                                <div className="md:col-span-5">
                                  <label
                                    className="lg:text-sm font-semibold text-indigo-900"
                                    htmlFor="Prenom">
                                    Calcul de l&rsquo;aide de la PAJE
                                    <p className="text-sm font-normal">
                                      (Prestation Accueil Jeune Enfant)
                                    </p>
                                  </label>
                                </div>

                                <div className="md:col-span-2 mt-4 md:text-md text-gray-600">
                                  <label htmlFor="nombreEnfants">
                                    Nombre d&rsquo;enfants (au foyer) :
                                  </label>
                                </div>
                                <div className="md:col-span-2 mt-2">
                                  <select
                                    id="nombreEnfants"
                                    className="w-full p-2 text-center font-normal rounded-lg text-indigo-900 lg:text-sm text-xs"
                                    value={nombreEnfants}
                                    onChange={handleNombreEnfantsChange}>
                                    <option value="">
                                      -- Choisissez le nombre d&rsquo;enfants --
                                    </option>
                                    <option value="1">1 enfant</option>
                                    <option value="2">2 enfants</option>
                                    <option value="3">3 enfants</option>
                                  </select>
                                </div>

                                <div className="md:col-span-2 md:text-md mt-4 text-gray-600">
                                  <label className="" htmlFor="revenu">
                                    Plafond de revenu pour {nombreEnfants}{" "}
                                    enfant{nombreEnfants > 1 ? "s" : ""} au
                                    foyer (inférieur à)
                                  </label>
                                </div>
                                <div className="md:col-span-2 mt-2">
                                  <select
                                    name="revenu"
                                    id="revenu"
                                    className="w-full revenu-select p-2 text-center font-normal rounded-lg text-indigo-900 lg:text-sm text-xs"
                                    value={revenu}
                                    onChange={handleRevenuChange}>
                                    <option
                                      className="lg:text-lg p-3 "
                                      value="">
                                      -- Choisissez votre plafond de revenu --
                                    </option>
                                    <option value="967.81">
                                      Revenus 1 &lt;{" "}
                                      {nombreEnfants === "1"
                                        ? "22 809 €"
                                        : nombreEnfants === "2"
                                        ? "26 046 €"
                                        : "29 283 €"}
                                    </option>
                                    <option value="834.28">
                                      Revenus 2 &lt;{" "}
                                      {nombreEnfants === "1"
                                        ? "50 686 €"
                                        : nombreEnfants === "2"
                                        ? "57 881 €"
                                        : "65 076 €"}
                                    </option>
                                    <option value="700.80">
                                      Revenus 3 &lt;{" "}
                                      {nombreEnfants === "1"
                                        ? "50 686 €"
                                        : nombreEnfants === "2"
                                        ? "57 881 €"
                                        : "65 076 €"}
                                    </option>
                                  </select>
                                </div>

                                <div className="md:col-span-2 mt-6">
                                  <div className="mt-3">
                                    <p className="text-md text-green-900">
                                      Montant CAF pour les - de 3 ans :
                                    </p>
                                    <p
                                      className="mt-4 text-green-800 text-2xl"
                                      name="montantAide"
                                      id="montantAide">
                                      {montantAide} €
                                    </p>
                                  </div>
                                </div>

                                <div className="md:col-span-5 mt-3">
                                  {/* <div className="inline-flex items-center">
                                                                    <input
                                                                        type="checkbox"
                                                                        name="IsParentIsole"
                                                                        id="IsParentIsole"
                                                                        className="form-checkbox"
                                                                        checked={IsParentIsole}
                                                                        onChange={(e) => setIsParentIsole(e.target.checked)}
                                                                    />
                                                                    <label htmlFor="IsParentIsole" className="ml-2">cocher si parents isolé</label>
                                                                </div> */}

                                 
                                </div>
                              </div>

                              {/* Resultat cout */}
                              <div className="md:w-1/4 border bg-blue-100 p-5 rounded-md mt-3 md:mt-0">
                                {/* Afficher le montant d'aide calculé */}
                                <div className="mt-8 bg-green-700 p-2 rounded-md">
                                  <p className="text-lg text-white">
                                    Coût mensuel estimé :
                                  </p>
                                  <p className="font-light italic text-xs text-gray-200">
                                    (PAJE déduite)
                                  </p>
                                  {montantAide > 0 && (
                                    <div className="md:col-span-3 mt-3">
                                      <p
                                        name="prixFinal"
                                        className="text-4xl text-white mt-3">
                                        {" "}
                                        {prixFinal} €
                                      </p>
                                    </div>
                                  )}
                                </div>
                                

                                <div className="flex justify-center my-10">
                            <hr className="border border-white w-1/2" />
                          </div>

                                <div className="text-xs mt-10 text-justify p-1 font-semibold text-red-700">
                                  Il faut ajouter le crédit
                                  d&rsquo;impôt de 50% avec un max de
                                  1750€/an soit une réduction en plus de 146€/mois
                                </div>

                                <div className="md:col-span-2 mt-3">
                                    <button
                                      id="reset-button"
                                      className="bg-red-600 hover:bg-red-700 text-white text-xs items-center px-9 py-2 rounded-lg"
                                      onClick={handleReset}>
                                      Réinitialiser le calcul
                                    </button>
                                  </div>

                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <input
                  type="hidden"
                  name="nombreEnfants"
                  value={nombreEnfants}
                />
                {/* <input type="hidden" name="revenu" value={revenu} /> */}
                <input type="hidden" name="montantAide" value={montantAide} />
                {/* <input
                  type="hidden"
                  name="selectedTableQF"
                  value={selectedTableQF}
                /> */}
                <input type="hidden" name="prixFinal" value={prixFinal} />
                
                <input type="hidden" name="monthlyCost" value={monthlyCost} />

                {/* Données de la sélection des jours */} 
                {Object.keys(selectionDay).map((day) => (
    <input
      key={day}
      type="hidden"
      name={`selectionDay[${day}]`}
      value={JSON.stringify(selectionDay[day])} // Valeur en format JSON pour gérer les objets
    />
))}
                <div className="md:col-span-5 text-right mt-4">
                  <div className="inline-flex items-end">
                    <button className="bg-green-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
                      Envoyé
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>

          <h2 className="text-xs text-justify mb-4 italic p-4">
            * Important : Notre structure se présente sous forme d’une petite
            SARL, elle est donc privée. Nous fonctionnons avec la PAJE
            micro-crèche. Ce type de modalité implique que nous facturons aux
            parents la globalité du coût du mode de garde et que la CAF reverse
            chaque mois aux parents (directement sur leur compte bancaire) une
            prestation évaluée en fonction des revenus annuels des parents. De
            même, il est important de rappeler que dans le cadre d’un contrat
            avec la micro-crèche, le parent n’est pas employeur contrairement à
            l’emploi d’une assistante maternelle. Le parent n’a donc aucune
            responsabilité particulière envers les salariés. La famille paie
            simplement une prestation de service. La CAF applique un plafond de
            prestation qu’il ne dépasse pas. Quoi qu’il en soit (si la facture
            mensuelle de la crèche est peu élevée) les parents garderont à leur
            charge 15% du montant du mode de garde. Une fois inscrit à la CAF,
            la responsable remplira directement (avec l’accord préalable des
            parents) une télé déclaration par internet sur un réseau sécurisé.
            C’est d’après cette télédéclaration que les services de la CAF se
            basent pour verser les prestations aux familles. Les parents n’ont
            donc aucune démarche à réaliser chaque mois. Notre structure
            applique un tarif variable en fonction des revenus des familles.
            Nous nous basons sur les QF (quotient familial de la CAF). Nous
            utilisons une facturation forfaitaire. Une demi-journée est facturée
            sur une base de 4h30 et une journée de 9h00. Tout dépassement,
            c&rsquo;est-à-dire toute journée de l’enfant qui excéderait 9h sera
            facturée au même tarif horaire sans supplément. En cas de litige, il
            est possible de faire appel à un médiateur à la consommation :Mme
            Laurence PRUD’HOMME / Tél : 06.12.95.62.18./ mail :
            laurence.prudhomme@free.fr
          </h2>

          <div>
            <p className="text-xs text-justify italic">
              Les informations recueillies sur ce site sont enregistrées dans un
              fichier informatisé par la Micro-crèche Grain de Malice pour
              pré-inscription. La base légale du traitement sont les intérêts
              légitimes des deux parties pour la préinscription. Les données
              collectées seront communiquées aux seuls destinataires suivants :
              émetteur du formulaire et la micro-crèche Grain de Malice. Les
              données sont conservées pendant le temps de l&rsquo;inscription et
              pour une durée de 36 mois maximum selon le RGPD. Vous pouvez
              accéder aux données vous concernant, les rectifier, demander leur
              effacement ou exercer votre droit à la limitation du traitement de
              vos données. (en fonction de la base légale du traitement,
              mentionner également : Vous pouvez retirer à tout moment votre
              consentement au traitement de vos données ; Vous pouvez également
              vous opposer au traitement de vos données ; Vous pouvez également
              exercer votre droit à la portabilité de vos données) Consultez le
              site cnil.fr pour plus d’informations sur vos droits. Pour exercer
              ces droits ou pour toute question sur le traitement de vos données
              dans ce dispositif, vous pouvez contacter La Micro-Crèche : 41 Rue
              Marx Dormoy, 42300 Roanne - Tél. : 09 81 65 51 57 ou 06 69 43 55
              26 - microcrecheroanne@gmail.com Si vous estimez, après nous avoir
              contactés, que vos droits « Informatique et Libertés » ne sont pas
              respectés, vous pouvez adresser une réclamation à la CNIL.
            </p>
          </div>
        </div>
      </section>
    </>
  );
};
