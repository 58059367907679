export const Projetdetail = () => {

    return (
        <section className="container mx-auto my-16 mt-16 bg-slate-100 rounded-lg lg:p-9 p-6 px-10 lg:px-52">

            <div className="text-justify content-center p-3">
                <div className=''>
                    <div id="" className="mt-1 text-xl md:text-3xl text-green-600 mb-3">Le projet pédagogique s’articule
                        autour de ...</div>

                    <h3 className="font-semibold text-2xl mt-10 text-orange-500">L’accueil des enfants</h3>

                    <div className="mt-3">

                        <p><b className="text-indigo-900">l’accueil journalier</b> du matin et du soir. C’est un moment privilégié qui permet de faire 
                        le lien entre la maison et la micro crèche. C’est un moment d’échange où l’on parle de l’enfant et de ses habitudes, de son quotidien.</p>

                        <p className="mt-2">Cet accueil est personnalisé et adapté
                            à chaque enfant et à chaque famille. Les professionnels sont disponibles pour échanger avec les familles.</p>
                            
                          <p className="mt-2">  Nous nous servons
                            d’un petit cahier comme support de communication et d’échange avec les familles. Les professionnelles notent les anecdotes
                            concernant l’enfant ainsi que les points importants de sa journée. Nous invitons les familles à se l’approprier.
                            Il deviendra ainsi une mémoire de la vie de l’enfant dans la micro-crèche.</p>

                        <p className='mt-2'><b className="text-indigo-900">La période d’adaptation</b> Elle permet l’accueil de l’enfant à la micro crèche en douceur. Cette période permet d’instaurer
                            la confiance et le dialogue entre les parents, enfant et professionnels. L’enfant peut alors faire connaissance
                            avec l’environnement dans lequel il évoluera et se familiariser avec les enfants accueillis.</p></div>



                    <h3 className="font-semibold text-2xl mt-10 text-orange-500">L’apprentissage de l’autonomie</h3>
                    <p className='mt-2'>Il est important que l’enfant apprenne à faire par lui-même et développe sa relation à l’autre :
                    </p>
                    <div className="">
                        <p className='mt-2 text-indigo-900 font-semibold'>Par les activités proposées et le jeu libre.</p>
                        <p className='mt-2 text-indigo-900 font-semibold'>Par la motricité.</p>

                        <p>Nous laissons l’enfant évoluer en fonction de ses capacités. Nous suivons son rythme et son développement
                            et l’encourageons dans son apprentissage. Nous respectons l’évolution différente de chaque enfant.</p>
                        <p className='mt-2 font-semibold text-indigo-900'>Par l’acquisition de la propreté et le change.</p>
                        <p>Nous suivons la demande de l’enfant
                            et prenons en compte sa maturité avec l’accord de ses parents. Nous respectons l’enfant et sa pudeur lors des changes
                            et des passages aux toilettes.</p>

                        <p className='mt-2'><b className="text-indigo-900">Par le sommeil.</b></p>
                        <p>Nous respectons le rythme et les besoins des enfants. Chaque enfant possède son propre lit.</p>

                        <p className='mt-2'><b className="text-indigo-900">Par le langage.</b></p>
                        <p>Nous accordons un intérêt tout particulier au langage et échangeons au maximum avec les enfants
                            car le langage est la prémisse de la vie sociale.
                        </p>

                        <p className='mt-2'><b className="text-indigo-900">Par l’alimentation.</b></p>
                        <p>Nous faisons des repas des moments privilégiés, conviviaux. Les repas sont proposés
                            aux enfants en fonction de leurs rythmes et sont adaptés à leur âge et à leur développement.
                            Les menus sont variés et nous cuisinons sur place avec un maximum de produits frais, bio et locaux.

                        </p></div>

                    <h3 className="font-semibold text-2xl mt-5 text-orange-500"> Le respect de l’enfant et de sa famille </h3>
                    <h4 className="mt-2 text-indigo-900 tracking-tight">Nous respectons l’enfant en tant que personne
                        et individu à part entière en étant vigilantes à :</h4>
                    <p className='mt-4 font-semibold text-indigo-900'>Son rythme</p>
                    <p className='mt-4 font-semibold text-indigo-900'>Sa sécurité affective et physique</p>

                    <p className='mt-4 font-semibold text-indigo-900'>Ses origines</p>
                    <p className='mt-4  font-semibold text-indigo-900'>Au respect de son bien être</p>

                    <h3 className="font-semibold text-2xl mt-5 text-orange-500"> L’équipe </h3>
                    {/* <h4 className="font-bold text-lg mt-2 mb-2 text-indigo-900" >Elle se compose de professionnels diplômés</h4> */}
                    <p className="tracking-tight">Elle se compose de quatre professionnels diplômés : deux éducatrices de jeunes enfants dont l’une exerce également
                        la fonction de responsable et co-gérante et deux professionnelles titulaire du CAP petite enfance.</p>
                    <p>Elles placent l’enfant au centre de leurs préoccupations et travaillent en respectant le projet pédagogique.</p>


                    <h3 className="font-semibold text-2xl mt-5 text-orange-500">Les parents</h3>
                    {/* <h4 className="font-bold text-lg mt-2 mb-2 text-indigo-900" > Les parents ont une place importante dans notre micro-crèche</h4> */}
                    <p> Les parents ont une place importante dans notre micro-crèche. Nous les incitons vivement à prendre part à la vie
                        de la structure. Nous proposons aux parents, s’ils le désirent, de participer occasionnellement à des activités
                        d’éveil avec le groupe d’enfants en proposant un thème qui les intéresse.</p>

                    <p className='mt-4'>Nous sommes à la disposition des parents
                        pour échanger sur leur enfant lors de l’accueil mais aussi lors de réunions ou soirées organisées pour les parents.</p>


                    <h3 className="font-semibold text-2xl mt-5 text-orange-500">Les stagiaires</h3>

                    <p className='mt-2'>Nous sommes favorables à l’accueil de stagiaires exerçant une formation diplômante dans le secteur de la petite enfance.</p>

                    <p className='mt-2'>Les stagiaires ne sont pas là pour remplacer le personnel mais pour se former. Ils participent aux activités sous le contrôle d’une personne
                    de référence. Chaque stagiaire est accueilli par sa référente et la directrice. </p>
                    
                    <p className='mt-2'>Nous n’acceptons pas de stage dont la durée est inférieure à un mois afin de ne pas 
                    déstabiliser les enfants. (Une période d’observation d’une semaine est appliquée pour chaque stage).</p>


                    <h3 className="font-semibold text-2xl mt-5 text-orange-500">L’ouverture vers l’extérieur </h3>
                    
                    <p className="mt-3">Nous proposons aux enfants dans la mesure du possible des sorties à l’extérieur telles que :</p>

                    <p className="mt-3 font-semibold text-indigo-900">Sorties au square, au marché, aux halles, à la médiathèque…</p>

                    <p className="mt-3">Nous proposons également une séance musique encadrée par un intervenant, Raphaël, qui anime un temps de 30 minutes une semaine sur deux.
                    Nous avons également des séances d’éveil corporel avec une professeur de danse diplômée (durant 6 séances par an vers le mois de juin).</p>
                    <p className="mt-3">Des pensionnaires de la maison de retraite voisine viennent nous rendre visite une semaine sur deux pour échanger et partager des activités avec les enfants.</p>

                    <h3 className="font-semibold text-2xl mt-5 text-orange-500">L’aménagement de l’espace</h3>
                    {/* <h4 className="font-bold text-lg mt-2 mb-2 text-indigo-900" >L’aménagement de nos locaux</h4> */}
                    <p className="mt-3">Nous avons travaillé sur l’aménagement de nos locaux afin de les adapter aux exigences d’une micro-crèche et les rendre fonctionnels
                        pour les familles et les professionnels. Nous nous sommes efforcés de les rendre accueillants et agréables pour tous.</p>

                    <p className="mt-3">Puis nous avons travaillé sur la disposition et le choix du mobilier. En effet, le mobilier doit être particulièrement adapté à
                        l’ergonomie des enfants.</p>

                    <p className="mt-3">L’aménagement de l’espace influence le comportement des enfants, dès lors une organisation adéquate permet de canaliser le groupe,
                        de faciliter le jeu et de laisser chacun évoluer sereinement.</p>

                    <h3 className="font-semibold text-2xl mt-5 text-orange-500">Notre démarche environnementale</h3>
                    {/* <h4 className="font-bold text-lg mt-2 mb-2 text-indigo-900" >Quelques règles pour un environnement sain</h4> */}
                    <p>Nous nous soucions de respecter quelques règles de principe afin que les enfants évoluent dans un environnement plus sain avec une vision à long terme.
                        Nous utilisons des produits ménagers et de toilette certifiée écologiques ou « bio », en évitant au maximum les produits jetables. Nous faisons attention
                        au gaspillage de matières premières et d’énergie, en triant nos déchets et en portant le compost avec les enfants, en proposant des produits de saison, locaux
                        et/ ou issu de l’agriculture biologique et toujours des viandes françaises …</p>
                </div>

                <p className="mt-5 font-semibold text-indigo-900">Fermeture annuelle : 5 semaines plus jours fériés.</p>
            </div>



        </section >
    )
}

