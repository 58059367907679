import React from 'react'

export const Repas = () => {
    return (
        <section className="bg-gradient-to-r from-red-500 to-indigo-500 text-gray-100 p-8 lg:h-full">
            <div className="container mx-auto flex flex-col lg:flex-row items-center justify-center lg:justify-around lg:px-8">
                {/* Image container */}
                <div className="w-full lg:w-2/5 flex justify-center lg:mb-10">
                    <img
                        className="w-full h-auto max-w-sm lg:max-w-md rounded-xl m-5"
                        src="/Assets/img/brooke-lark-jUPOXXRNdcA-unsplash.jpg"
                        alt="image_restaurant"
                    />
                </div>

                {/* Text content */}
                <div className="w-full lg:w-3/5 p-4 lg:p-12">
                    {/* <button id="bottom_little_salade" className="mx-1 p-2 mt-4 mb-4 text-xs bg-lime-400 rounded-md">
                        Projet pédagogique
                    </button> */}

                    <div className="my-2">
                        <h5 id="text-grain" className="font-semibold text-2xl md:text-3xl lg:text-5xl text-cyan-300">
                            Les repas sont préparés sur place
                        </h5>
                    </div>

                    <p className="text-white mt-4 mb-10 lg:mb-5">
                        Les repas sont préparés sur place avec des produits frais et de saison
                        (prix du repas compris dans le tarif horaire hors alimentation lactée pour les bébés).
                    </p>

                    {/* <button className="bg-white text-black my-4 p-2 rounded-md mb-2 md:mb-4">
                        En savoir plus
                    </button> */}
                </div>
            </div>

            <div id="anim-banner" className="bg-lime-300 text-gray-700 w-full -mt-12 -z-10"></div>
        </section>

    )
}
