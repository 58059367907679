import React from "react";
import { Header } from "../components/Header";
import { Footer } from "../components/Footer";
import { Microcreches } from "../components/Projet/Microcreches";
import { Projetdetail } from "../components/Projet/Projetdetail";

export const Projects = () => {
  return (
    <>
      <Header />
      <Microcreches />
      <Projetdetail />
      <Footer />
    </>
  );
};
