import React from 'react'
// import { Link } from 'react-router-dom'

export const Microcreches = () => {
    return (
        <section className="container mx-auto my-16 mt-36">


            <div className="flex flex-col lg:flex-row lg:items-center justify-center">
                <div className="lg:w-4/5 sm:w-1/1">

                    <div id="enfant_play" className=" block md:md:hidden w-full lg:w-1/3 sm:w-1/1 p-3 lg:m-7">
                        <img className="h-full w rounded-xl"
                            src="/Assets/img/kenny-eliason-uaQpinemVoo-unsplash.jpg"
                            alt="childplay" />
                    </div>



                    <div className="container lg:pr-20 sm:pr-1 my-2 p-7 lg:mt-2">

                        <h5 className="text-2xl text-indigo-900">Grain de Malice
                            <h1 id="text-grain" className="mt-1 text-6xl text-green-600">Projet Pédagogique</h1>
                        </h5>

                    </div>

                    <div className="container lg:pr-20 p-7 text-indigo-900 text-justify">
                        <p className=""> <p classNameName='text-orange-600 font-semibold mb-4'>Un projet pédagogique est un outil de communication entre parents, professionnels et instances extérieures destiné à présenter toutes les valeurs éducatives ainsi que les différentes pratiques de notre structure.</p>  Il nous sert de base de travail et de fondation. C’est sur cette base que repose chacune de nos pratiques auprès des enfants.
                            Il permet d’entretenir la cohérence des relations entre professionnels mais aussi avec les parents. Il est le garant de notre travail. Le projet éducatif reprend toutes les valeurs qui nous animent et le projet pédagogique définit la manière dont nous les mettons en pratique au quotidien. C’est un support à notre travail.
                            Nous avons souhaité consigner par écrit non seulement nos valeurs éducatives mais aussi la façon dont nous les mettons en pratique. Par cette démarche nous voulons que les parents aient une connaissance complète de notre façon de fonctionner. Ainsi le choix d’inscrire un enfant dans notre micro-crèche nécessite l’approbation de notre pédagogie.

                        </p>
                        <div className="flex items-center mt-10">
                            <div className="ml-20 lg:w-3/4 justify-center flex-row lg:items-center">
                                <img src="/Assets/img/happy-children.png" alt="Enfants_joyeux" /></div>
                        </div>

                    </div>
                    {/* <div className="flex items-center justify-between w-96 mt-5">
                        <Link className="rounded-md py-2 px-4 bg-orange-400 text-white">Notre équipe</Link>
                        <button className="mx-2 rounded-md py-2 px-4 flex items-center">
                            <img className="mx-2 " src="/Assets/svg/picto-video.svg" alt="" /><p className="text-indigo-900">Voir la vidéo</p></button>
                    </div> */}
                </div>
                <div id="enfant_play" className="hidden lg:block w-3/5 sm:1/2 m-10">
                    <img className="h-full w rounded-xl"
                        src="/Assets/img/kenny-eliason-uaQpinemVoo-unsplash.jpg"
                        alt="parents_enfants" />

                    <img className="h-full w rounded-xl mt-10"
                        src="/Assets/img/enfant_microcreche_cuisine_fille.png"
                        alt="parents_enfants" />
                </div>
            </div>
            <div className="my-20"></div>
            {/* <button className="flex items-center">
      <img className="mx-2" src="./ressources/svg/picto-scroll.svg" alt=""/>
      En savoir plus
    </button>  */}
        </section>
    )
}
