
export const MicroCreche = () => {
    return (
        <>
            <section className="container mx-auto my-16 mt-36">


                <div className="flex flex-col lg:flex-row lg:items-center justify-center">
                    <div className="lg:w-4/5 sm:w-1/1">

                        <div id="enfant_play" className=" block md:md:hidden w-full lg:w-1/3 sm:w-1/1 p-3 lg:m-7">
                            <img className="h-full w rounded-xl"
                                src="/Assets/img/equipe.png"
                                alt="childplay" />
                        </div>



                        <div className="container lg:pr-20 sm:pr-1 my-2 p-7 lg:mt-2">

                            <h5 className="mt-5 text-2xl text-indigo-900">Grain de Malice
                                <h1 id="text-grain" className="mt-2 text-6xl text-green-600">Nous connaître</h1>
                            </h5>

                            <h5 className="container lg:pr-20 font-semibold text-xl mt-5 text-orange-500">
                                Notre équipe est composée de professionnelles titulaires de diplômes de la petite enfance :</h5>
                        </div>


                        <div className="container lg:pr-20 px-7 text-indigo-900 text-justify">

                            <h3 className="font-semibold">Cécile BERCHOUX : Diplôme d’Etat EJE : éducatrice de jeunes enfants</h3>
                            Cécile s’occupe au quotidien des enfants
                            accueillis dans notre établissement (activités d’éveil, soins d’hygiène, repas…).
                            Elle est la référence technique de la micro crèche et assure ainsi le respect du projet pédagogique.
                            Cécile occupe la fonction de responsable de la structure. Elle est également cogérante de la société.


                            <h3 className="font-semibold mt-4">Aurélie MONARD : Titulaire du CAP petite enfance</h3>
                            Aurélie travaille au quotidien auprès des enfants en proposant des activités diverses
                            et en assurant les soins nécessaires aux enfants (hygiène, repas, siestes…) Aurélie est cogérante de la société.


                            <h3 className="font-semibold mt-4"> Marilyne MARCHAND : Titulaire du CAP petite enfance</h3>
                            Marilyne travaille au quotidien auprès des enfants en proposant des activités diverses et
                            en assurant les soins nécessaires aux enfants (hygiène, repas, siestes…)
                            Marilyne prépare les repas donnés aux enfants.

                            <h3 className="font-semibold mt-4"> Marianne MONTEGU : Diplôme d’Etat EJE : éducatrice de jeunes enfants</h3>
                            Marianne travaille au quotidien auprès des enfants en proposant des activités diverses
                            et en assurant les soins nécessaires aux enfants (hygiène, repas, siestes…)
                            
                            <h1 className="mt-3 font-semibold text-green-600">Nous préparons nous-même les repas.</h1>



                            <div className="flex items-center mt-10">
                                <div className="lg:ml-16 lg:w-3/4 justify-center flex-row items-center">
                                    <img src="/Assets/img/Cecile_aurelie.png" alt="Cecile&Aurelie" /></div>
                            </div>

                        </div>
                        {/* <div className="flex items-center justify-between w-96 mt-5">
            <Link className="rounded-md py-2 px-4 bg-orange-400 text-white">Notre équipe</Link>
            <button className="mx-2 rounded-md py-2 px-4 flex items-center">
                <img className="mx-2 " src="/Assets/svg/picto-video.svg" alt="" /><p className="text-indigo-900">Voir la vidéo</p></button>
        </div> */}
                    </div>
                    <div id="enfant_play" className="hidden lg:block w-3/5 sm:1/2 m-10">
                        <img className="h-full w rounded-xl"
                            src="/Assets/img/creche_exterieur.png"
                            alt="parents_enfants" />

                        <img className="h-full w rounded-xl mt-10"
                            src="/Assets/img/equipe.png"
                            alt="parents_enfants" />
                    </div>
                </div>
                <div className="my-20"></div>
                {/* <button className="flex items-center">
<img className="mx-2" src="./ressources/svg/picto-scroll.svg" alt=""/>
En savoir plus
</button>  */}
            </section>
        </>
    )
}
