
import { Link } from 'react-router-dom'

export const Microcreche = () => {
    return (
        <section className="container mx-auto my-16 mt-36">
            <div className="flex flex-col lg:flex-row lg:items-center justify-center">
                <div className="lg:w-4/5 w-full">

                    <div id="enfant_play" className="block md:hidden w-5/6 lg:w-1/3 sm:w-full m-4 shadow-lg">
                        <img className="h-full w-full rounded-xl" src="/Assets/img/graindemalice-childs.png" alt="childplay" />
                    </div>

                    <div className="flex items-center mt-3">
                        <div className="ml-14 lg:w-1/4 w-2/3 flex justify-center lg:items-center">
                            <img src="/Assets/img/baby-crawling-with-green-diaper.png" alt="baby" />
                        </div>
                    </div>



                    <div className="container lg:pr-20 sm:pr-1 my-2 p-7 lg:mt-2">
                        <h1 className="text-4xl text-indigo-900">Micro-crèche
                            <h1 id="text-grain" className="mt-1 text-6xl text-green-600">Grain de Malice</h1>
                        </h1>
                        <h2 className="font-semibold text-lg text-orange-500 mt-6">
                            La micro-crèche Grain de Malice est un lieu d’accueil de jeunes enfants avec une capacité de 12 places.
                            Les enfants peuvent être accueillis de 2 mois et demi à 3 ans (et de 3 à 6 ans en périscolaire) accompagnés
                            par des professionnels de la petite enfance.</h2>

                            <h2 className="font-semibold text-lg text-indigo-900 mt-5">Nos particularités :</h2>

                            <h2 className="font-semibold text-md text-indigo-900 mt-3"> Un agrément pour 12 enfants *, encadrés par 4 professionnels.</h2>

                            <h2 className="font-semibold text-md text-indigo-900 mt-3"> Une micro-crèche privée avec une vision différente.</h2>
                            <p className='text-indigo-900 text-justify'>Nous ne sommes pas une société de crèches avec une gestion d’entreprise. Nous avons créé un projet
                                à petite échelle qui met en avant les valeurs pédagogiques qui nous tiennent à cœur. Ce projet est à l’initiative de deux
                                professionnelles de la petite enfance expérimentées.</p>

                                <p className='text-indigo-900 text-justify mt-3'> Nous accueillons les enfants <b>sans distinction</b> quant au lieu de résidence de leurs parents et nous n’avons pas de partenariat entreprise.</p>
                                <h2 className="font-semibold text-md text-indigo-900 mt-3"> Un accueil spécifique entre individualité et collectivité.</h2>

                                <h2 className="font-semibold text-md text-indigo-900 mt-3"> Notre expérience et notre formation professionnelle : un gage de qualité.</h2>
                                <p className='text-indigo-900 text-justify'>Les enfants sont encadrés par des professionnels titulaires de diplômes reconnus (diplôme d’Etat d’Educateur de Jeunes Enfants, CAP petite enfance…)
                                ayant une très large expérience et réflexion dans le domaine de l’accueil du jeune enfant.</p>

                                <p className='text-indigo-900 text-justify mt-3'>Notre réflexion sur l’accueil de l’enfant et sa famille
                                    a abouti à <b>un projet pédagogique élaboré.</b></p>

                                    <p className='font-semibold text-md text-indigo-900 mt-3'> Les repas sont préparés sur place avec des produits frais et de saison</p>
                                       <p className='text-indigo-900 text-justify '> (prix du repas compris dans le tarif horaire hors alimentation lactée pour les bébés).</p>

                                <p className='font-semibold text-md text-indigo-900 mt-3'> Un environnement adapté aux enfants et à leurs besoins</p>
                                    <p className='text-indigo-900 text-justify'>(sécurité et confort).
                                    Nous avons aménagé 110 m² de locaux et réfléchi à l’espace et aux jeux afin qu’il soit conçu pour l’enfant et son autonomie.
                                    (Avec un espace sanitaire, du mobilier, une structure de motricité adaptés…).</p>

                                    <p className='font-semibold text-md text-indigo-900 mt-3 tracking-tight'> Notre petite plus, un petit grain de malice, qui nous permet d’être une structure 
                                        « pas comme les autres » </p> <p className='text-indigo-900 text-justify '>… Nous proposons des spectacles pour enfant chaque fin d’année que nous concevons nous-même, en équipe. 
                                        Nous proposons des temps conviviaux aux parents et connaissons bien chaque famille, ce qui confère une atmosphère chaleureuse, 
                                        propre à notre micro-crèche. Enfin, nous souhaitons éveiller la curiosité artistique et intellectuel des enfants grâce à notre 
                                        passion pour les arts et la création : peinture, fabrication de marionnettes, création de spectacle, musique, et invention 
                                        en tout genre…en bref, nous ne nous donnons aucune limite à la créativité.</p>

                                        <p className='text-xs italic mt-2 text-indigo-900'>*(ce qui signifie que l’on ne peut accueillir plus de 13 enfants en même temps)*
                                        </p><p className='text-xs italic mt-2 text-indigo-900'>SARL M.C.C.A. au capital de 10 000 euros</p>

                    </div>
                    

                    <div className="flex mt-6 ml-4">
                        {/* <button id="bottom_little_new_recette" className="rounded-md py-2 px-3 bg-orange-400 text-white">
                            <Link to="/about" className="text-white no-underline font-semibold hover:text-lime-100">Notre équipe</Link>
                        </button> */}
                        <button id="bottom_little_salade" className="ml-5 rounded-md py-2 px-3 bg-lime-400">
                            <Link to="/projects" className="text-cyan-800 no-underline font-semibold hover:text-lime-700">Projet pédagogique</Link>
                        </button>
                    </div>
                </div>
                <div id="enfant_play" className="hidden lg:block lg:w-2/5 sm:w-1/2 m-10">
                    {/* <img className="h-full rounded-xl" src="/Assets/img/sieste.png" alt="child_play" /> */}
                    <img className="mt-20 h-full rounded-xl" src="Assets/img/creche_exterieur.png" alt="creche exterieur" />
                    <img className="mt-20 h-full rounded-xl" src="/Assets/img/interieur1.jpg" alt="child_play" />

                </div>

                
            </div>
            <div className="my-20"></div>
        </section>

    )
}
