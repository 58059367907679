
import { Header } from '../components/Header'
import { Footer } from '../components/Footer'
import { Formulaire } from '../components/Inscription/Formulaire'

export const Inscription = () => {
  return (
    <>
      <Header />
      <Formulaire />
      <Footer />
    </>
  )
}
