import { Link } from 'react-router-dom'

export const Activite = () => {
    return (
        <>
            <div className="container my-10 md:mt-12  p-3">
                <div className="flex flex-wrap items-center justify-center">

                    <div className="w-full md:w-1/2 lg:w-1/3 p-2">
                        <Link to="/projects"> <div className="flex flex-col justify-center max-w-sm mx-auto bg-orange-100 rounded-lg shadow hover:bg-orange-300">
                            <img className="h-60 w-full object-cover object-center rounded-t-lg"
                                src="/Assets/img/jefferson-santos-fCEJGBzAkrU-unsplash.jpg" alt="" />
                            <div className="p-4">
                                <h3 id="card" className="font-semibold text-xl my-2 text-center">Chants, comptines et histoires</h3>
                                <p className="py-5 text-justify">À la micro-crèche Grain de Malice, nous croyons fermement en l'importance
                                    de l'éveil artistique et culturel pour le développement des jeunes enfants. C'est
                                    pourquoi nous intégrons régulièrement des activités de chansons, de comptines et
                                    de contes adaptés à leur âge. Ces moments musicaux où les enfants découvrent et apprennent des chansons et comptines variées,
                                    favorisant leur éveil auditif et leur sens du rythme.</p>
                            </div>
                        </div></Link>
                    </div>

                    <div className="w-full md:w-1/2 lg:w-1/3 p-2">

                        <Link to="/projects"> <div className="flex flex-col justify-center max-w-sm mx-auto bg-cyan-100 rounded-lg shadow hover:bg-cyan-300">
                            <img className="h-60 w-full object-cover object-center rounded-t-lg"
                                src="/Assets/img/phil-hearing-cylPETXS7is-unsplash.jpg" alt="" />
                            <div className="p-4">
                                <h3 id="card" className="font-semibold text-xl my-2 text-center">peinture, pâte à modeler, collages</h3>
                                <p className="py-8 text-justify">À la micro-crèche Grain de Malice, nous encourageons l'expression artistique
                                    des enfants avec des activités de dessin et de graphisme. Chaque jour, les enfants explorent différentes
                                    techniques comme la peinture et le collage, stimulant leur créativité et motricité fine. Encadrés
                                    par des professionnelles bienveillantes, ils développent leur imagination dans un environnement valorisant et épanouissant.</p>
                            </div>
                        </div></Link>
                    </div>


                    <div className="w-full md:w-1/2 lg:w-1/3 p-2">


                        <Link to="/projects">   <div className="flex flex-col justify-center max-w-sm mx-auto bg-lime-100 rounded-lg shadow hover:bg-lime-300">
                            <img className="h-60 w-full object-cover object-center rounded-t-lg"
                                src="/Assets/img/conscious-design-xoWNIXzULMs-unsplash.jpg" alt="" />
                            <div className="p-4">
                                <h3 id="card" className="font-semibold text-xl my-2 text-center">cuisine, transvasement</h3>
                                <p className="py-8 text-justify">À la micro-crèche Grain de Malice, nous intégrons des activités culinaires
                                    pour stimuler l'éveil des enfants. En participant à des ateliers
                                    adaptés, les tout-petits découvrent les aliments, développent leur motricité
                                    fine et apprennent la patience. Les activités de transvasement, quant à elles,
                                    améliorent leur coordination et leur concentration, propice à leur épanouissement.</p>
                            </div>
                        </div></Link>

                    </div>
                </div>
            </div>
        </>
    )
}
