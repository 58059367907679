import React from 'react'
import { Header } from '../components/Header'
import { Footer } from '../components/Footer'
import { MicroCreche } from '../components/AboutUs/MicroCreche'
import { Maps } from '../components/AboutUs/Maps'

export const AboutUs = () => {
  return (
    <>
      <Header />
      <MicroCreche />
      <section className="container mx-auto mt-4">
        <Maps />
      </section>
      <Footer />
    </>
  )
}
