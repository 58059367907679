import {  useState,  } from 'react';
import { Link, useLocation } from 'react-router-dom';

import { useTheme } from '../ThemeContext';


export const Header = () => {
    const currentLocation = useLocation();
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    const { theme, toggleTheme, disableDarkMode } = useTheme();

    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
    };

    return (
        <header className="header w-full fixed bg-gradient-to-r from-cyan-400 to-cyan-600 top-0 shadow-lg">
            <div className="hidden">
                <p>Le thème actuel est {theme}</p>
                <button onClick={toggleTheme}>
                    Basculer le thème
                </button>
                <button onClick={disableDarkMode}>
                    Désactiver le mode sombre
                </button>
            </div>

            <div className="max-w-6xl mx-auto flex justify-between items-center h-32">

                <button onClick={toggleMenu} className="md:hidden burger-icon">
                    <img className="p-5" src="/Assets/svg/burger2.svg" alt="menu_burger" />
                </button>

                <Link to="/">
                    <div className="flex items-center mr-7">
                        <img className="w-12 mr-5" src="/Assets/img/logo.png" alt="logo_grain de malice" />
                        <h1 id="text-grain" className="whitespace-nowrap pr-10 md:p-5  text-white text-4xl lg:text-3xl xl:text-4xl">Grain de Malice</h1>

                    </div>
                </Link>


                <nav className="hidden md:flex items-center w-3/5 justify-around">
                    <ul className="flex items-center w-full justify-around text-xl">
                        <li>
                            <Link
                                to="/"
                                className={`${currentLocation.pathname === '/' ? 'font-medium text-cyan-800' : 'text-white hover:text-lime-200'}`}
                            >
                                Accueil
                                <p className="text-center font-normal text-sm">Présentation</p>
                            </Link>
                        </li>
                        <li>
                            <Link
                                to="/about"
                                className={`${currentLocation.pathname === '/about' ? 'font-medium text-cyan-800' : 'text-white hover:text-lime-200'}`}
                            >
                                Nous connaître
                                <p className="text-center font-normal text-sm">L&rsquo;équipe</p>
                            </Link>
                        </li>
                        <li>
                            <Link
                                to="/projects"
                                className={`${currentLocation.pathname === '/projects' ? 'font-medium text-cyan-800' : 'text-white hover:text-lime-200'}`}
                            >
                                Projet pédagogique
                                <p className="text-center font-normal text-sm">Projet éducatif</p>

                            </Link>
                        </li>
                    </ul>
                </nav>

                <Link className="whitespace-nowrap overflow-hidden ml-4 hidden md:block bg-lime-400 hover:bg-lime-500 text-blue-700 hover:text-blue-600 font-semibold px-9 py-2 rounded-lg w-52"
                    to="/pre-inscription">
                    Pré-inscription
                    <p className="text-center font-normal text-sm">Tarification</p>
                </Link>
            </div>

            {isMenuOpen && (
                <div id="mobile-menu" className="md:hidden bg-gradient-to-r from-cyan-400 to-cyan-600">
                    <ul className="flex flex-col items-center">
                        <li className="py-2">
                            <Link
                                to="/"
                                className="text-white hover:text-amber-200"
                                onClick={toggleMenu}
                            >
                                Accueil
                            </Link>
                        </li>
                        <li className="py-2">
                            <Link
                                to="/about"
                                className="text-white hover:text-amber-200"
                                onClick={toggleMenu}
                            >
                                Nous connaître

                            </Link>
                        </li>
                        <li className="py-2">
                            <Link
                                to="/projects"
                                className="text-white hover:text-amber-200"
                                onClick={toggleMenu}
                            >
                                Projet pédagogique
                            </Link>
                        </li>
                        <li className="py-2">
                            <Link
                                to="/pre-inscription"
                                className="text-white hover:text-amber-200"
                                onClick={toggleMenu}
                            >
                                Pré-inscription
                            </Link>
                        </li>
                    </ul>
                </div>


            )}


        </header>
    )
}
